$bg-color: #201E23;
$primary-highlight-color:#88C1EB;
$highlight-color-2 : #FFFFFF;
$highlight-color-3: rgba(255, 255, 255, 0.6);
$bg-radial-background: radial-gradient(circle at right, #1b2735 50%, $bg-color 90%);

ul,li {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
}

ul li::marker{
  color: $primary-highlight-color;
}

html {
  height: 100%;
}

body {
  background: $bg-radial-background;
  font-family: 'Outfit', sans-serif !important;
}

.ml-1{
  margin: {
    left: 1rem;
  }
}

.send-message-btn {
  width: 119px;
  height: 29px;
  border: 1px solid $primary-highlight-color;
  border-radius: 5px;
  color: $primary-highlight-color;
}

.mw-fit-content {
  max-width: fit-content !important;
}

.mw-mx-fit-content{
  min-width: fit-content !important;
  max-width: fit-content !important;
}



highlight-text {
  color: $primary-highlight-color;
}

highlight-text-2 {
  color: $highlight-color-2
}

highlight-text-3 {
  color: $highlight-color-3;
}

.tech-stacks {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  padding: {
    top: 2px;
    bottom: 2px;
    left: 4px;
    right: 4px;
  }
  background-color: #bbb;
  width: fit-content;
  border-radius: 5px;
  color: black;
}

@keyframes showup{
  from {opacity: 0}
  to {opacity: 1}
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes image-showup{
  from {opacity: 0.5}
  to {opacity: 1}
}

@keyframes move-image {
  from {top: -1rem; left: -1rem}
  to {top: 0rem; left: 0rem}
}

@keyframes grow-shrink {
  from {
    height: 100px;
    width: 100px;
  }
  50% {
    height: 105px;
    width: 105px;
  }
  to  {
    height: 100px;
    width: 100px;
  }
}

@keyframes backgroundFill {
  from {
    background: $primary-highlight-color;
  }
  to {
    background: transparent;
  }
}

@keyframes expandFont {
  from {
    font-size: 20px;
  }
  to {
    font-size: 50px;
  }
}

@keyframes animStar{
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}

@function multiple-box-shadow($n){
  $value: '#{random(2000)}px #{random(2000)}px #FFF';
  @for $i from 2 through $n{
    $value: '#{$value}, #{random(2000)}px #{random(2000)}px #FFF';
  }
  @return unquote($value);
}

$shadows-small: multiple-box-shadow(500);
$shadows-medium: multiple-box-shadow(100);
$shadows-big: multiple-box-shadow(50);

.stars{
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $shadows-medium;
  animation: animStar 50s linear infinite;
}

.stars1{
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: $shadows-big;
  animation: animStar 100s linear infinite;
}


.footer-container{
  div{
    padding: 1rem;
    font-size: 12px;
    z-index: 100;
    background: $bg-radial-background;

    border-top: {
      left-radius: 5px;
      right-radius: 5px;
    }
    
    border: {
      top: 1px solid rgba(136, 146, 176, 0.15);
      left: 1px solid rgba(136, 146, 176, 0.15);
      right: 1px solid rgba(136, 146, 176, 0.15);
    }

  }
  bottom: 0px;
}

.navbar-logo{
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 2px solid $primary-highlight-color;
  color: $primary-highlight-color;
  // font-family: "SF Mono","Fira Code","Fira Mono","Roboto Mono",monospace;
}

.logo-loader{
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 5px solid $primary-highlight-color;
  animation: 1s grow-shrink infinite, 2s backgroundFill forwards, 3s expandFont forwards;
  color: $primary-highlight-color;
  // font-family: "SF Mono","Fira Code","Fira Mono","Roboto Mono",monospace;
}


.line-up {
  animation: 4s anim-lineUp ease-out;
}

.show-up{
  animation: 4s showup forwards;
}

// All font sizes
.fs-17{
  // font-size: 17px;
  font-size: clamp(14px,5vw, 16px);
}

.fs-18{
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-30{
  // font-size: 30px;
  font-size: clamp(26px,5vw, 32px);
}

.fs-50 {
  font-size: clamp(40px, 5vw, 60px);
}

.fs-80 {
  // font-size: 70px;
  font-size: clamp(40px, 8vw, 80px);
}

.fs-70 {
  font-size: clamp(40px, 8vw, 70px);
}

.bg-color {
  background-color: $bg-color;
}

.cursor-pointer{
  cursor: pointer;
}

.blog-container-modal{
  padding-left: 0px !important;
  .modal-dialog{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 80%;
    height: -webkit-fill-available;
    .modal-content{
      height: fit-content;
      max-height: 80vh;
      
      background-color: $bg-color;

      .modal-header{
        border-bottom: none;
        color: $highlight-color-2;
        h6{
          color: $highlight-color-3;
        }
      }

      .modal-body{
        overflow-y: auto;
        color: $highlight-color-2;
        h6{
          color: $highlight-color-3;
        }
        p{
          color: rgba(136, 146, 176, 0.9);
        }
      }
    }
  }
}


// Custom Primary Button
.custom-primary__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $primary-highlight-color;
  border-radius: 5px;
  color: $primary-highlight-color;
  width: max-content;
  padding: {
    left: 3rem;
    right: 3rem;
    top: 0.7rem;
    bottom: 0.7rem;
  }
}

// Base-container
.base-container {
  // min-height: 100%;
  // height: fit-content;
  padding: {
    left: 7rem !important;
    bottom: 7rem;
    top: 10rem !important;
    right: 7rem !important;
  }
}

.header-line {
  display: none;
  border: 1px solid $highlight-color-3;
  width: 308px;
}

// Navbar
.navbar-container {
  z-index: 100;
  font-size: 15px;
  font-weight: 100;
  background: $bg-radial-background;
  .navbar {
    a {
      color: white !important;
    }
  
    .navbar-collapse {
      justify-content: flex-end;
    }

    .header-contact__btn{
      padding-left: 1rem !important;
      padding-right: 1rem !important;
      padding: {
        top: 0.4rem;
        bottom: 0.4rem;
      }
      font-size: 13px;
    }
  }
  animation: 3s anim-lineUp forwards;
}



//  Main Banner Container
.main-banner-container{
  .follow-icon-container{
    div{
      border: 1px solid $primary-highlight-color;
      // &:hover{
      //   transition: 2s;
      //   background:  $primary-highlight-color;
      // }
      img {
        height: 30px;
      }
    }
  }
}

.main-banner-showup{
  animation-name: showup;
  animation-duration: 5s;
}

// About me container
.about-me-container {

  .about-me-header {
    color: $highlight-color-2;
  }

  .about-me-photo {
    .photo-container {
      border: 1px solid $primary-highlight-color;
      box-shadow: 0px 0px 4px 1px $primary-highlight-color;
      border-radius: 50%;
      height: fit-content;
      .photo-box{
        img {
          height: 270px;
        }
      }
      animation: 4s showup forwards;
    }
  }
}

// Work history container
.work-history-container {

  height: 70%;

  .work-history-header {
    color: $highlight-color-2;
  }

  .react-tabs {
    display: flex;
    color: white;
    border-left: 1px solid $highlight-color-3;
    animation: showup 4s forwards;
    margin: {
      top: 2.5rem;
    }
  }

  .react-tabs__tab-list {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    color: white;
  }

  .react-tabs__tab {
    list-style: none;
    cursor: pointer;
    color: #bbb;
    width: max-content;
    margin-left: -1.5px;
    padding: {
      top: 1rem;
      bottom: 1rem;
    }
    transition: 1s;
  }

  .react-tabs__tab--selected {
    border-left: 2px solid $primary-highlight-color;
    color: $primary-highlight-color;
    transition: 1s;
    
  }

  .react-tabs__tab-panel {
    display: none;
    margin: {
      left: 4rem;
    }
  }

  .react-tabs__tab-panel--selected {
    display: block;
    animation: 2s showup forwards;
  }

  .react-tabs__tab {
    padding-left: 24px;
  }

  .react-tabs__tab--selected {
    padding-left: 21px;
  }

  .panel-content {
    .panel-content-header {
      header-title {
        font-size: 20px;
      }

      header-date {
        font-size: 15px;
        color: $highlight-color-3;
      }
    }

    .panel-content-desc {
      color: $highlight-color-3;
    }
  }
}

// Blogs container
.blogs-container {
  // height: fit-content !important;

  .blogs-header {
    color: $highlight-color-2;
  }
}

// Get In Touch cotainer
.get-in-touch-container {

  .get-in-touch-header {
    header {
      color: $highlight-color-2;
    }

    sub-header{
      text-align: center;
    }
  }

  .key-services{

    .description{
      color: $highlight-color-3;
    }
    ul{
      li{
        color: white !important;
      }
    }
  }
}

// Media Query
@media only screen and (max-width: 1400px) {
  .base-container{
    padding: {
      left: 5rem !important;
      right: 5rem !important;
    }
  }
}


@media only screen and (max-width: 1200px) { 
  .base-container{
    padding: {
      left: 3rem !important;
      right: 3rem !important;
    }
  }
}



@media only screen and (max-width: 992px) {

  .footer-container{
    div{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0px;
      border: {
        left: none;
        right: none;
      }
    }
  }
  .navbar-container{

    border-bottom: 1px solid rgba(136, 146, 176, 0.3);
    
    .navbar{
      .navbar-collapse{
        .navbar-nav{
          li{
            display: flex;
            justify-content: center;
            font-size: 20px;
            font-weight: 500;
          }
        }
      }
    }
  }

  .base-container{
    padding: {
      left: 0rem !important;
      right: 0rem !important;
    }
  }
} 

@media only screen and (max-width: 768px) {

  .main-banner-container{
    p{
      margin-bottom: 0rem !important;
    }
  }

  .about-me-container{
    .about-me-body{
      .col{
        min-width: fit-content;
      }
    }
    .about-me-photo{
      .photo-container{
        margin-top: 3rem;
        .photo-box{
          background-color: transparent;
          top: 0rem;
          left: 0rem;
        }
      }
    }
  }

  .work-history-container{
    min-height: 100%;
    height: fit-content;
    .react-tabs{
      flex-direction: column;
      border: none;
      

      .react-tabs__tab-list{
        flex-direction: row;
        overflow: auto;
        white-space: nowrap;
        border-bottom: 1px solid $highlight-color-3;

        .react-tabs__tab{
          padding-right: 24px;
        }

        .react-tabs__tab--selected{
          border-left: none;
          border-bottom: 2px solid $primary-highlight-color;
          padding-left: 24px;
          padding-right: 24px;
        }

        &::-webkit-scrollbar{
          display: none;
        }
      }

      .react-tabs__tab-panel{
        margin-left: 0rem;
        margin-top: 3rem;
      }
    }
    
  }

  .get-in-touch-container{
    .get-in-touch-header {
      p{
        header{
          text-align: center;
        }
        sub-header{
          display: none;
        }
      }
    }
    .key-services{
      .col{
        flex: none;
        input{
          width: 100% !important;
        }
        textarea{
          width: 100% !important;
        }

        .send-message-btn{
          width: 100%;
          height: 40px;
          margin-bottom: 3rem;
        }

        justify-content: flex-start !important; 
      }

      .custom-primary__btn{
        margin-top: 1rem !important;
      }

      .info-container{
        margin-top: 3rem;
      }
    }
  }
} 

@media only screen and (max-width: 600px) {


  .base-container{
    padding: {
      left: 2rem !important;
      right: 2rem !important;
    }
  }
} 

